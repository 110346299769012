import React from 'react';
import Partneri from './Partneri';
import Ucet from './Ucet';
import Percenta from './Percenta';

export default function Podporte() {
  return (
    <div>
      <Percenta />
      <Ucet />
      <Partneri />
    </div>
  );
}
